import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Tubbies Fried Chicken is a popular takeaway restaurant located in
        Crawley. As the name suggests, the restaurant specializes in serving a
        variety of fried chicken dishes, prepared with a unique blend of spices
        and seasoning.
      </Typography>
      <Typography variant="body1" paragraph>
        The menu at Tubbies Fried Chicken includes a range of options such as
        chicken wings, strips, burgers, and wraps, all cooked to order and
        served fresh. Customers can also choose from a selection of deliciously
        crafted pizzas
      </Typography>
      <Typography variant="body1" paragraph>
        Tubbies Fried Chicken takes pride in using high-quality ingredients and
        cooking methods that result in delicious and juicy chicken dishes.
      </Typography>
      <Typography variant="body1" paragraph>
        Overall, Tubbies Fried Chicken is a great choice for anyone who loves
        fried chicken and wants to experience a delicious meal in a friendly and
        relaxed atmosphere.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
